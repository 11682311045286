import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from "../layouts/en"
import Seo from "../components/seo"
import SimplePage from "../templates/simple-page"
import BackLink from "../components/back-link"

const content = [
  {
    title: "Customer satisfaction and focus",
    copy:
      "We stand for ambitious high quality products, prompt delivery and expert service. We seek to establish long-term partnerships with our customers in order to fulfil their requirements, suggestions and preferences.",
  },
  {
    title: "Quality",
    copy:
      "Finkernagel products are manufactured using the state-of-the-art technology and are of the highest quality. It is our aim to maintain and build on this quality standard through continuous improvement at all levels of our organisation.",
  },
  {
    title: "Employee qualification and occupational safety",
    copy:
      "Our expert and motivated employees identify with our company philosophy and assume a high level of personal responsibility for its implementation. The active involvement and ongoing training of our workforce ensures constant development of our expertise. The safety of our employees at the workplace is fundamental in our view. Therefore we work consistently to improve the standard of occupational safety which we have already achieved.",
  },
  {
    title: "Sustainability",
    copy:
      "We take our responsibility for the environment seriously and act to protect it e.g. by installing state-of-the-art facilities. We promote our employees’ awareness of our environment and we are constantly improving our operational environmental protection methods.",
  },
  {
    title: "Process orientation",
    copy:
      "The processes we utilise in production are stable and carefully managed. It is our aim to control company processes and drive further development based on reliable data.",
  },
  {
    title: "Continuous improvement",
    copy:
      "Finkernagel products and the processes and facilities used to manufacture them are being constantly improved and modernised. We want to maintain and increase our leading market position from economic perspectives with progressive solutions.",
  },
  {
    title: "Suppliers",
    copy:
      "We work together with our qualified and trusted suppliers on a long-term basis. It is our declared aim to meet new challenges in close cooperation with our partners to ensure joint progress.",
  },
]

const PrivacyPage = () => {
  const { images } = useStaticQuery(
    graphql`
      query {
        images: allFile(
          filter: { relativePath: { glob: "policy/*.jpg" } }
          sort: { fields: name, order: ASC }
        ) {
          nodes {
            childImageSharp {
              gatsbyImageData(quality: 85, layout: FULL_WIDTH)
            }
          }
        }
      }
    `
  )

  return (
    <Layout>
      <Seo
        title="Our company philosophy"
        description="We are a successful independent family business with an internationally recognised position in the market. Our employees actively participate in the implementation of our company philosophy."
      />
      <SimplePage title="Our company philosophy">
        <BackLink to="/company" />
        <p className="intro">
          We are a successful independent family business with an
          internationally recognised position in the market. Our employees
          actively participate in the implementation of our company philosophy.
        </p>

        {content.map((block, i) => (
          <section key={i}>
            {i in images.nodes && (
              <figure className="-mb-2 mt-12">
                <GatsbyImage
                  image={images.nodes[i].childImageSharp.gatsbyImageData}
                  alt={block.title}
                />
              </figure>
            )}
            <h2 className="text-black">{block.title}</h2>
            <p>{block.copy}</p>
          </section>
        ))}
      </SimplePage>
    </Layout>
  )
}

export default PrivacyPage
